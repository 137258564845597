import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.css'],
})
export class StepsComponent implements OnInit {
  @Input() paso: number = 0;
  items: MenuItem[]=[];

  constructor() {}

  ngOnInit() {
    this.loadNavegacion();
  }

  loadNavegacion() {
    this.items = [
      { label: 'Revisión ' },
      { label: 'Direcciones' },
      { label: 'Forma de Pago' },
      { label: 'Finalizar ' },
    ];
  }
}
