import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

import { Router } from '@angular/router';
import {
  HttpClient,
  HttpResponse,
  HttpHeaders,
  HttpRequest,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

import {
  UserRegistration,
  ICodigoNombre,
  IOperationResult,
  IToken,
  ICart,
  TokenResponse,
} from '../utils/interfaces';
import { ConfigService } from './config.service';
import { BaseService } from './base.service';
import { Observable, pipe, Observer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BehaviorSubject } from 'rxjs';

//import { resolve } from 'url';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  authKey: string = 'auth';
  clientId: string = '1';
  baseUrl: string = '';

  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private _authName = new BehaviorSubject<string>('');
  authName$ = this._authName.asObservable();

  private _numItem = new BehaviorSubject<number>(0);
  numItem$ = this._numItem.asObservable();

  private loggedIn = false;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    super();
    if (isPlatformBrowser(this.platformId)) {
      this.loggedIn = !!localStorage.getItem('auth_token');
      

      if (this.isLoggedIn()) {
        this._authName.next(this.getAuth()!.display_name);
        this._authNavStatusSource.next(true);
       
      }else
      {
        this._authNavStatusSource.next(false);
      }
    }
    this.baseUrl = configService.getApiURI();
  }

  ngOnDestroy() {}
  register(
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    location: string
  ): Observable<any> {
    let body = JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      location,
    });
    return this.http
      .post<any>(this.baseUrl + '/accounts', body, {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      })
      .pipe(
        map((res) => true),
        catchError(this.handleError)
      );
  }

  login(userName:string, password:string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .post<IToken>(
        this.baseUrl + 'auth/login',
        JSON.stringify({ userName, password }),
        { headers: new HttpHeaders().set('Content-Type', 'application/json') }
      )

      .pipe(
        map((res) => {
          localStorage.setItem('auth_token', res.auth_token);
          this.loggedIn = true;
          this._authNavStatusSource.next(true);

          return true;
        }),
        catchError(this.handleError)
      );
  }

  getCartAut(): Observable<any> {
    let authToken = this.getAuth()!.token;

    return this.http.get(this.baseUrl + 'cart', {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + authToken),
    });
  }

  getCart(): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http
        .get<ICart>(
          this.baseUrl + 'cart/' + JSON.parse(localStorage.getItem('cart')!),
          { headers: headers }
        )
        .pipe(
          map((res) => {
         
            if (res != null) {
              this._numItem.next(res.cartItem.length);
            } else {
              this._numItem.next(0);
            }
            return res;
          }),
          catchError(this.handleError)
        );
    }
  }
  actNumItems(numero: number) {
   
    this._numItem.next(numero);
  }
  login2(userName:string, password:string) {
    this.loggedIn = true;
    this._authNavStatusSource.next(true);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .post<IToken>(
        this.baseUrl + 'token/auth2',
        JSON.stringify({ userName, password }),
        { headers: new HttpHeaders().set('Content-Type', 'application/json') }
      )

      .pipe(
        map((res) => {
          localStorage.setItem('auth_token', res.token);
          localStorage.setItem('auth_name', res.name);
          this.loggedIn = true;
          this._authNavStatusSource.next(true);
          this._authName.next(res.name);

          return true;
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('auth_name');

    this.loggedIn = false;
    this._authNavStatusSource.next(false);
    this._authName.next('');
    this.router.navigate(['']);
  }

  facebookLogin(accessToken: string) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');

    return this.http
      .post<IToken>(
        this.baseUrl + '/externalauth/facebook',
        JSON.stringify({ accessToken }),
        { headers: new HttpHeaders().set('Content-Type', 'application/json') }
      )

      .pipe(
        map((res) => {
          localStorage.setItem('auth_token', res.token);
          this.loggedIn = true;
          this._authNavStatusSource.next(true);

          return true;
        }),
        catchError(this.handleError)
      );
  }
  login3(username: string, password: string): Observable<TokenResponse> {
    var url = this.baseUrl + 'token/auth';
    var data = {
      username: username,
      password: password,
      client_id: this.clientId,

      grant_type: 'password',

      scope: 'offline_access profile email',
    };

    return this.getAuthFromServer2(url, data);
  }

  register3(
    username: string,
    email: string,
    password: string,
    repeatpassword: string
  ): Observable<IOperationResult> {
    var url = this.baseUrl + 'user';
    var data = {
      username: username,
      email: email,
      password: password,
      repeatpassword: repeatpassword,
    };
    console.log(data);
    return this.http.put<IOperationResult>(url, data).pipe(
      map(
        (res) => {
          return res;
        },
        (err: HttpErrorResponse) => {
          console.log(err.error);
          console.log(err.name);
          console.log(err.message);
          console.log(err.status);
        }
      )
    );
  }

  // retrieve the access & refresh tokens from the server
  getAuthFromServer2(url: string, data: any): Observable<TokenResponse> {
    return this.http.post<any>(url, data).pipe(
      map(
        (res) => {
          let token = res.token;

          if (token) {
            this.setAuth(res);

            return res;
          }

          console.log('http 2');
          return res;
        },
        (err: HttpErrorResponse) => {}
      )
    );
  }

  sendemail(username: string): Observable<any> {
    var data = {
      username: username,
    };
    let body = JSON.stringify(data);
    console.log(username);
    return this.http.post(this.baseUrl + 'token/sendemail', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  changepassword(
    username: string,
    oldpssword: string,
    newpassword: string,
    repeatpassword: string
  ): Observable<any> {
    var data = {
      username: username,
      password: oldpssword,
      newpassword: newpassword,
      repeatpassword: repeatpassword,
    };
    let body = JSON.stringify(data);
    console.log(username);
    return this.http.post(this.baseUrl + 'token/changepassword', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  resetpassword(
    username: string,
    newpassword: string,
    repeatpassword: string,
    token: string
  ): Observable<any> {
    var data = {
      username: username,
      newpassword: newpassword,
      repeatpassword: repeatpassword,
      refresh_token: token,
    };
    let body = JSON.stringify(data);
    console.log(username);
    return this.http.post(this.baseUrl + 'token/resetpassword', body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    });
  }

  refreshToken(): Observable<TokenResponse> {
    var url = this.baseUrl + 'token/auth';
    var data = {
      client_id: this.clientId,

      grant_type: 'refresh_token',
      refresh_token: this.getAuth()!.refresh_token,

      scope: 'offline_access profile email',
    };

    return this.getAuthFromServer2(url, data);
  }

  setAuth(auth: TokenResponse | null): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (auth) {
        localStorage.setItem(this.authKey, JSON.stringify(auth));

        this._authName.next(this.getAuth()!.display_name);
       this._authNavStatusSource.next(true);
      
      } else {
        localStorage.removeItem(this.authKey);
        this._authName.next("Invitado");
        this._authNavStatusSource.next(false);
       
      }
    }
    return true;
  }

  logout2(): boolean {
    this.setAuth(null);

    return true;
  }

  getAuth(): TokenResponse | null {
    if (isPlatformBrowser(this.platformId)) {
      var i = localStorage.getItem(this.authKey);
      if (i) {
        return JSON.parse(i);
      }
    }
   
    return null;
  }
  method2Call(): void {
    this.http
      .get('https://jsonplaceholder.typicode.com/user12')
      .subscribe((success) => {
        console.log('Successfully Completed');
        console.log(success);
      });
  }

  isLoggedIn(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(this.authKey) != null;
    }

    return false;
  }
}
