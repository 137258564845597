import { Component,Input,Output,EventEmitter} from '@angular/core';


import {ConfirmationService} from 'primeng/api';

import {Message} from 'primeng//api';
@Component({
  selector: 'app-alert-delete',
  templateUrl: './alert-delete.component.html',
  styleUrls: ['./alert-delete.component.css'],
  providers: [ConfirmationService]
})
export class AlertDeleteComponent  {
  @Output() aceptar = new EventEmitter<void>();
  @Input() mensajeEliminacion :  string ="Esta seguro que desea eliminar el registro?";
  msgs: Message[] = [];
  
  constructor(private confirmationService: ConfirmationService) {}

  confirm1() {
      this.confirmationService.confirm({
          message: 'Are you sure that you want to proceed?',
          header: 'Confirmation',
          icon: 'fa fa-question-circle',
          accept: () => {
              this.msgs = [{severity:'info', summary:'Confirmed', detail:'You have accepted'}];
          },
          reject: () => {
              this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          }
      });
  }

  confirm2() {
      console.log(this.mensajeEliminacion);
      this.confirmationService.confirm({
          message: this.mensajeEliminacion,
          header: 'Confirmar Eliminacion',
          icon: 'pi pi-trash',
          accept: () => {
              this.aceptar.emit();
             // this.msgs = [{severity:'info', summary:'Confirmado', detail:'Registro eliminado'}];
          },
          reject: () => {
              //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
          }
      });
  }
}