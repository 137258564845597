import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { ConfigService } from '@app/services/config.service';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';




@Component({
  selector: 'app-input-quantity',
  templateUrl: './input-quantity.component.html',
  styleUrls: ['./input-quantity.component.css']
})
export class InputQuantityComponent implements OnInit {



  @Input('master') masterName!: string;
  @Input() Size:number =1;
  public isBrowser = isPlatformBrowser(this.platformId);
  //@Input() quantity: number;


  @Output() quantityChange = new EventEmitter<number>();
  private _quantity: number = 0;

  @Input() set quantity(quantity: number) {
      this._quantity = quantity;
  }

  get quantity(): number { return this._quantity; }




  isMobile: boolean=false;
  constructor(
      private configService: ConfigService,
      @Inject(PLATFORM_ID) private platformId: any
  ) {

  }
  onChange(cambio: any) {

      this.quantityChange.emit(cambio);
  }

  ngOnInit() {

  }



}
