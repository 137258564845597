<div *ngIf="!status">
<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <!-- <a [routerLink]="['/']"> -->
      <!-- <img src="http://ventasapi3.comprobantes.info/images/listas/EDDING.JPG" height="40" class="p-mr-2">
     -->
    
      <img
      src="{{ apiHost }}images/listas/{{ logo }}"
      [routerLink]="['/']"
      alt=""
    />


    <!-- </a> -->
    </ng-template>
  <ng-template pTemplate="end">
  
    <section *ngIf="isBrowser">
    
    <a [routerLink]="['/cart']">
      <button
        type="button"
        pButton
        label="{{ numItem }}"
        icon="pi pi-shopping-cart"
        style="margin-left: 0.25em"
      ></button>
    </a>
    </section>
  </ng-template>
</p-menubar>
</div>

<div *ngIf="status">
  <p-menubar [model]="items2">
    <ng-template pTemplate="start">
      <!-- <a [routerLink]="['/']">
        <img src="http://ventasapi3.comprobantes.info/images/listas/EDDING.JPG" height="40" class="p-mr-2">
     -->
        <img
        src="{{ apiHost }}images/listas/{{ logo }}"
        [routerLink]="['/']"
        alt=""
      />

      
      <!-- </a> -->
      </ng-template>
    <ng-template pTemplate="end">
    
      <section *ngIf="isBrowser">
        {{name}}
      <a [routerLink]="['/cart']">
        <button
          type="button"
          pButton
          label="{{ numItem }}"
          icon="pi pi-shopping-cart"
          style="margin-left: 0.25em"
        ></button>
      </a>
      </section>
    </ng-template>
  </p-menubar>
  </div>
  
<!--   

<div *ngIf="!status">
<p-menubar [model]="items">
  <div>
    <section *ngIf="isBrowser">
      <input type="text" pInputText placeholder="Search" />
{{name}}{{status}}
      <a [routerLink]="['/cart']">
        <button
          type="button"
          pButton
          label="{{ numItem }}"
          icon="pi pi-shopping-cart"
          style="margin-left: 0.25em"
        ></button>
      </a>
    </section>
  </div>
</p-menubar>
</div>

<div *ngIf="status">
<p-menubar [model]="items2">
  <div>
    <section *ngIf="isBrowser">
      <input type="text" pInputText placeholder="Search" />
      {{name}}{{status}}
      <a [routerLink]="['/cart']">
        <button
          type="button"
          pButton
          label="{{ numItem }}"
          icon="pi pi-shopping-cart"
          style="margin-left: 0.25em"
        ></button>
      </a>
    </section>
  </div>
</p-menubar>
</div> -->
