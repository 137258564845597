import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ConfigService {


  _apiURI: string;
  _tipo: string;




    constructor(){
        this._apiURI = 'http://localhost:10143/api/';
       this._apiURI = 'http://ventasapi5.comprobantes.info/api/';

      //  this._apiURI = 'http://ventasapi3.comprobantes.info/api/';
        this._tipo = "A";
     //   this._tipo = "U";


    }


  getApiURI() {
    return this._apiURI;
  }
  getApiHost() {
    return this._apiURI.replace('api/', '');
  }


  getNavegador() {

   // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


    // if (isMobile) {
    //   return true;
    // }

    return false;
  }
  getInputNumber() {
    var isMobile = this.getNavegador();

    if (isMobile) {
      var isBrowser = /Firefox/i.test(navigator.userAgent);

      if (isBrowser)
        return false;
      else
        return true;
    }

    else
      return false;
  }
}
