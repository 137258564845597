import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@app/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { ReturnStatement } from '@angular/compiler';
import { ConfigService } from '@app/services/config.service';

@Component({
  selector: 'app-menu-home',
  templateUrl: './menu-home.component.html',
  styleUrls: ['./menu-home.component.css'],
})
export class MenuHomeComponent implements OnInit {
  public items: MenuItem[] = [];
  items2: MenuItem[] = [];
  subscription!: Subscription;
  subscriptionumItem!: Subscription;
  subscriptioName!: Subscription;
  status: boolean = false;
  numItem: number = 0;
  name: string = 'guest';
  name2: string = 'guest';
  tipoA: boolean = false;
  tipoU: boolean = false;
  logo: string = '';
  logoSm: string = '';
  apiHost: string = '';
  isGuest: boolean = false;
  public isBrowser = isPlatformBrowser(this.platformId);
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public userService: AuthService,
    public router: Router,
    public config: ConfigService
  ) {}

  Inicio() {
    this.apiHost = this.config.getApiHost();
    this.logo = 'papeleria-lg.png';
    this.logoSm = 'papeleria-sm.png';

    if (this.config._tipo == 'A') this.tipoA = true;
    if (this.config._tipo == 'U') this.tipoU = true;

    this.subscriptionumItem = this.userService.numItem$.subscribe(
      (numItem) => (this.numItem = numItem)
    );
    this.subscription = this.userService.authNavStatus$.subscribe(
      (status) => (this.status = status)
    );

    this.subscriptioName = this.userService.authName$.subscribe((name2) => {
      this.name = name2;
    });
    // this.subscriptioName = this.userService.authName$.subscribe(
    //   name => this.items[0].label = name
    // );

    this.items = [
      {
        label: 'AUTOS',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/linea2/AM'],
        visible: this.tipoA,
      },
      {
        label: 'Catalogo',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/linea/LI'],
      },
      {
        label: 'Marca',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/lista/MA'],
      },
      {
        label: 'Utiles Escolares',
        icon: 'pi pi-fw pi-pencil',
        visible: this.tipoU,
        items: [
          {
            label: 'Generador',
            icon: 'pi pi-fw pi-align-left',
            routerLink: ['/producto/generador/GUE/G'],
          },
          {
            label: 'Instituciones Educativas',
            icon: 'pi pi-fw pi-align-right',
            routerLink: ['/producto/institucion/UE'],
          },
        ],
      },

      {
        label: 'Invitado',
        icon: 'pi pi-fw pi-user',

        id: 'Item1',
        items: [
          {
            label: 'Ingresar',
            icon: 'pi pi-fw pi-user-minus',
            routerLink: ['/auth'],
          },
          {
            label: 'Registarse',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/auth/register'],
          },
        ],
      },
    ];
    this.items2 = [
      {
        label: 'AUTOS',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/linea2/AM'],
        visible: this.tipoA,
      },
      {
        label: 'Catalogo',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/linea/LI'],
      },
      {
        label: 'Marca',
        icon: 'pi  pi-power-off',
        routerLink: ['/producto/lista/MA'],
      },
      {
        label: 'Utiles Escolares',
        icon: 'pi pi-fw pi-pencil',
        visible: this.tipoU,
        items: [
          {
            label: 'Generador',
            icon: 'pi pi-fw pi-align-left',
            routerLink: ['/producto/generador/GUE/G'],
          },
          {
            label: 'Instituciones Educativas',
            icon: 'pi pi-fw pi-align-right',
            routerLink: ['/producto/institucion/UE'],
          },
        ],
      },

      {
        label: '',
        icon: 'pi pi-fw pi-user',

        id: 'Item1',
        items: [
          {
            label: 'Pedidos',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/order'],
          },
          {
            label: 'Cambiar',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: ['/auth/change'],
          },
          {
            label: 'Salir',
            icon: 'pi pi-fw pi-power-off',
            command: () => {
              this.end();
            },
          },
        ],
      },
    ];
  }
  cambiar() {

    // this.items[0].label=this.name;
  }
  end() {
    this.userService.logout2();

    this.router.navigate(['/']);
  }
  ngOnInit(): void {
    this.Inicio();
    this.getCart();
  }
  getCart() {
    if (this.isBrowser) {
      this.userService.getCart().subscribe(
        (res) => {
          if (res != null) {
          } else {
          }
        },
        (error) => {
          {
          }
        }
      );
    }
  }
}
