import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css'],
})
export class SearchProductComponent {
  @Input() tipo: string = 'E';
  @Output() buscar = new EventEmitter<string>();

  buscarTexto: string = '';
  //  faSearch=faSearch;
  constructor(private router: Router) {}
  Buscar() {
    if (this.buscarTexto != '') this.buscar.emit(this.buscarTexto);
  }
  buscarp() {
    if (this.buscarTexto != '') {
      if (this.tipo == 'E"')
        this.router.navigate([
          '/producto/listado/:type/:name',
          { type: 'T', name: this.buscarTexto },
        ]);
      else this.buscar.emit(this.buscarTexto);
    }
  }
}
