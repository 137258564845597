

<section  *ngIf="isBrowser">

  <p-inputNumber
  [(ngModel)]="quantity"
  [showButtons]="true"
  buttonLayout="horizontal"

  spinnerMode="horizontal"
  [step]="1"
  (ngModelChange)="onChange($event)"
  decrementButtonClass="p-button-danger" [size]="Size"
  incrementButtonClass="ui-button-info"
  incrementButtonIcon="pi pi-plus"
  decrementButtonIcon="pi pi-minus"

  >
  </p-inputNumber>

<!--
<p-inputNumber
[(ngModel)]="quantity"
[showButtons]="true"
buttonLayout="horizontal"
inputId="horizontal"
spinnerMode="horizontal"
[step]="1"
(ngModelChange)="onChange($event)"

    decrementButtonClass="ui-button-secondary"
    incrementButtonClass="ui-button-info"
    incrementButtonIcon="pi pi-plus"
    decrementButtonIcon="pi pi-minus"
    [min]="0" [size]="Size" >
</p-inputNumber> -->

</section>



