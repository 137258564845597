import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputQuantityComponent } from './input-quantity/input-quantity.component';

import { InputNumberModule } from 'primeng/inputnumber';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';

import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToastModule} from 'primeng/toast';
import {StepsModule} from 'primeng/steps';
import { ProgressSpinnerComponent } from './progress/progress-spinner/progress-spinner.component';
import { ProgressBarComponent } from './progress/progress-bar/progress-bar.component';
import { MenuBreadComponent } from './menu/menu-bread/menu-bread.component';
import { MenuHomeComponent } from './menu/menu-home/menu-home.component';
import { SearchProductComponent } from './search-product/search-product.component';
import { StepsComponent } from './steps/steps.component';
import { AlertDeleteComponent } from './alert-delete/alert-delete.component';

@NgModule({
  declarations: [
    InputQuantityComponent,
    ProgressSpinnerComponent,
    ProgressBarComponent,
    MenuBreadComponent,
    MenuHomeComponent,
    SearchProductComponent,
    StepsComponent,
    AlertDeleteComponent
  ],
  imports: [
    CommonModule,
    InputNumberModule,
    FormsModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    MenubarModule,
    ConfirmDialogModule,
    InputTextModule,
    ButtonModule,
    BreadcrumbModule,
    StepsModule,
    ToastModule
  ],
  exports: [
    InputQuantityComponent,
    ProgressSpinnerComponent,
    ProgressBarComponent,
    MenuBreadComponent,
    MenuHomeComponent,
    SearchProductComponent,
    StepsComponent,
    AlertDeleteComponent
  ],
})
export class ControlsModule {}
