

    <div class="grid grid-cols-2  sm:grid-cols-4 px-10 sm:px-2 2xl:px-20 pt-2">






        <div class="sm:col-span-3">
          <input
            type="text"

            [(ngModel)]="buscarTexto"
            class="form-field"
            placeholder="Buscar Producto"
          />



        </div>
        <div>
          <button class="btn-normal  pl-1" aria-label="Left Align" id="bTexto" (click)="buscarp()">


            <i class="pi pi-search" style="font-size: 1.5rem"></i>


          </button>
        </div>
      </div>



<!--
  <div *ngIf="tipo == 'III'">
    <div class="p-d-none p-d-md-inline">

      <div class="p-grid" style="border: thin solid black;">
        <div class="p-col-12 p-md-2"></div>

        <div class="p-col-12 p-md-8">
          <div class="p-inputgroup">
            <input
              type="text"
              pInputText
              [(ngModel)]="buscarTexto"
              class="busqueda"
              placeholder="Buscar Producto"
            />

            <button class="btn btn-primary btn-lg" id="bTexto" (click)="Buscar()">
              <i class="pi pi-search" style="font-size: 1rem"></i>
            </button>
          </div>
        </div>

        <div class="p-col-12 p-md-2"></div>
      </div>
    </div>
    <div class="p-d-md-none">

      <div class="ui-inputgroup" style="padding-top: 5px;padding-bottom: 5px;">
        <div class="p-inputgroup">
        <input
          type="text"
          pInputText
          [(ngModel)]="buscarTexto"
          class="busqueda-s"
          placeholder="Buscar Producto"
        />

        <button class="btn btn-primary btn-lg" id="bTexto" (click)="Buscar()">
          <i class="pi pi-search" style="font-size: 1rem"></i>
        </button>
        </div>
      </div>
    </div>
  </div>
 -->
