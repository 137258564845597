import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  @Input() valor:number =0;
    value: number = 60;
  constructor() { }

  ngOnInit(): void {
  }

}
