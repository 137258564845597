import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
   { path: 'auth', loadChildren:  () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
   { path: 'cart', loadChildren:  () => import('./modules/cart/cart.module').then(m => m.CartModule) },
   { path: 'producto', loadChildren: () => import('./modules/catalog/catalog.module').then(m => m.CatalogModule) },
    { path: 'order', loadChildren:  () => import('./modules/order/order.module').then(m => m.OrderModule) },
  
   {
     path: '',
     redirectTo: 'producto',
     pathMatch: 'full',
   }
 ];

 


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
