import { Component, OnInit, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '@app/services/auth.service';
@Component({
  selector: 'app-menu-bread',
  templateUrl: './menu-bread.component.html',
  styleUrls: ['./menu-bread.component.css'],
})
export class MenuBreadComponent implements OnInit {
  home: MenuItem;

  @Input() items!: MenuItem[];

  constructor(public userService: AuthService) {
    this.home = { icon: 'pi pi-fw pi-home', routerLink: ['/'] };
  }

  ngOnInit(): void {}
}
