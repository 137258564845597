import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import {
    HttpClient,
    HttpHandler, HttpEvent, HttpInterceptor,
    HttpRequest, HttpResponse, HttpErrorResponse
} from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { Observable ,throwError} from "rxjs";
import {tap ,catchError} from 'rxjs/operators';
//import { FaLayersTextComponent } from '@fortawesome/angular-fontawesome';
@Injectable()
export class AuthResponseInterceptor implements HttpInterceptor {

    currentRequest!: HttpRequest<any>;
    auth!: AuthService;

    constructor(
        private injector: Injector,
        private router: Router
    )
    { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {

        this.auth = this.injector.get(AuthService);
        var token = (this.auth.isLoggedIn()) ? this.auth.getAuth()!.token : null;

        if (token) {
            // save current request
            this.currentRequest = request;

            return next.handle(request)
                .pipe(tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // do nothing
                    }
                })
                ,catchError(error => {
                    return this.handleError(error)
                }));
        }
        else {
            return next.handle(request);
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,


          console.error(
            `Backend returned code ${error.status}, ` +
            `body was: ${error.error}`);
            if (error.status === 401 || error.status === 403 ) {

                this.auth.logout2();
                this.router.navigate(["/auth/login"]);
               // return Observable.throw(err);
            }
            else
            {
               
            }


        }
        // return an observable with a user-facing error message
         return throwError(
          'Something bad happened; please try again later.');

         // return Observable.throw(error.error);
      };

    handleErrorBorrar(err: any) {
      
        if (err instanceof HttpErrorResponse) {
            console.log("error");
            console.log(err);
            alert("error handle 2 "+err.status);
            if (err.status === 401 || err.status === 403 ) {
                // JWT token might be expired:
                // try to get a new one using refresh token
                this.auth.logout2();
                this.router.navigate(["/auth/login"]);
                return Observable.throw(err);
             //   console.log("Token expired. Attempting refresh...");
            //     this.auth.refreshToken()
            //         .subscribe(res => {
            //         if (res) {
            //             // refresh token successful
            //             console.log("refresh token successful");

            //             // re-submit the failed request
            //             var http = this.injector.get(HttpClient);
            //             http.request(this.currentRequest).subscribe(
            //                 (result:any) => {
            //                     // do something
            //                 }, (error:any) => console.error(error)
            //             );
            //         }
            //         else {
            //             // refresh token failed
            //             console.log("refresh token failed");

            //             // erase current token
            //             this.auth.logout();

            //             // redirect to login page
            //             this.router.navigate(["login"]);
            //         }
            //     }, error => console.log(error));
             }
             else
             {
                 alert("va a otr lado");
             }
        }
        return Observable.throw(err);
    }
}
