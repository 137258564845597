

<p-toast  ></p-toast >

    <p-confirmDialog acceptLabel='SI'></p-confirmDialog>
    
    
    
    <!-- <button class="ui-button-danger" type="button" (click)="confirm2()" pButton icon="fa fa-trash" ></button> -->
    
    
    <button class="btn btn-danger" (click)="confirm2()"><i
        class="pi pi-trash " style="font-size: 2em"
        aria-hidden="true"></i></button>
    
    
    